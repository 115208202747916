/*=== MEDIA QUERY ===*/
/*
Theme Name: Medic
Author: Themefisher
Author URI: https://themefisher.com/
Description: Medicle Template
Version: 1.0.0

*/
@import url("https://fonts.googleapis.com/css?family=Exo:500,600,700|Roboto&display=swap");
html {
  overflow-x: hidden;
}

body {
  line-height: 1.6;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  font-size: 16px;
  color: #6F8BA4;
  font-weight: 400;
}

h1, .h1, h2, .h2, h3, .h3, h4, .h4, h5, .h5, h6, .h6 {
  font-family: "Exo", sans-serif;
  font-weight: 700;
  color: #222;
}

h1, .h1 {
  font-size: 2.5rem;
}

h2, .h2 {
  font-size: 44px;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.3rem;
  line-height: 30px;
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  line-height: 30px;
}

.navbar-toggle .icon-bar {
  background: #223a66;
}

input[type="email"], input[type="password"], input[type="text"], input[type="tel"] {
  box-shadow: none;
  height: 45px;
  outline: none;
  font-size: 14px;
}

input[type="email"]:focus, input[type="password"]:focus, input[type="text"]:focus, input[type="tel"]:focus {
  box-shadow: none;
  border: 1px solid #223a66;
}

.form-control {
  box-shadow: none;
  border-radius: 0;
}

.form-control:focus {
  box-shadow: none;
  border: 1px solid #223a66;
}

.py-7 {
  padding: 7rem 0px;
}

.btn {
  display: inline-block;
  font-size: 14px;
  font-size: 0.8125rem;
  font-weight: 700;
  letter-spacing: .5px;
  padding: .75rem 2rem;
  font-family: "Exo", sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  border: 2px solid transparent;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.btn.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
  padding-left: 15px;
}

.btn:focus {
  outline: 0px;
  box-shadow: none;
}

.btn-main {
  background: #223a66;
  color: #fff;
  border-color: #223a66;
}

.btn-main:hover {
  background: #e12454;
  border-color: #e12454;
  color: #fff;
}

.btn-main-2 {
  background: #e12454;
  color: #fff;
  border-color: #e12454;
}

.btn-main-2:hover {
  background: #223a66;
  color: #fff;
  border-color: #223a66;
}

.btn-solid-border {
  border: 2px solid #223a66;
  background: transparent;
  color: #223a66;
}

.btn-solid-border:hover {
  border: 2px solid #223a66;
  color: #fff;
  background: #223a66;
}

.btn-solid-border:hover.btn-icon i {
  border-left: 1px solid rgba(255, 255, 255, 0.09);
}

.btn-solid-border.btn-icon i {
  border-left: 1px solid rgba(0, 0, 0, 0.09);
}

.btn-transparent {
  background: transparent;
  color: #222;
  border-color: #6F8BA4;
}

.btn-transparent:hover {
  background: #6F8BA4;
  color: #fff;
}

.btn-white {
  background: #fff;
  border-color: #fff;
  color: #222;
}

.btn-white:hover {
  background: #223a66;
  color: #fff;
  border-color: #223a66;
}

.btn-solid-white {
  border-color: #fff;
  color: #fff;
}

.btn-solid-white:hover {
  background: #fff;
  color: #222;
}

.btn-round {
  border-radius: 4px;
}

.btn-round-full {
  border-radius: 50px;
}

.btn.active:focus, .btn:active:focus, .btn:focus {
  outline: 0;
}

.bg-gray {
  background: #eff0f3;
}

.bg-primary {
  background: #223a66;
}

.bg-primary-dark {
  background: #152440;
}

.bg-primary-darker {
  background: #090f1a;
}

.bg-dark {
  background: #222;
}

.bg-gradient {
  background-image: linear-gradient(145deg, rgba(19, 177, 205, 0.95) 0%, rgba(152, 119, 234, 0.95) 100%);
  background-repeat: repeat-x;
}

.section {
  padding: 100px 0;
}

.section-sm {
  padding: 70px 0;
}

.section-bottom {
  padding-bottom: 100px;
}

.subtitle {
  color: #223a66;
  font-size: 14px;
  letter-spacing: 1px;
}

.overlay:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0.9;
  background: #223a66;
}

.overly-2 {
  position: relative;
}

.overly-2:before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
}

.text-sm {
  font-size: 14px;
}

.text-md {
  font-size: 2.25rem;
}

.text-lg {
  font-size: 3.75rem;
}

.no-spacing {
  letter-spacing: 0px;
}

/* Links */
a {
  color: #222;
  text-decoration: none;
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

a:focus, a:hover {
  color: #e12454;
  text-decoration: none;
}

a:focus {
  outline: none;
}

.content-title {
  font-size: 40px;
  line-height: 50px;
}

.page-title {
  padding: 120px 0px 70px 0px;
  position: relative;
}

.page-title .block h1 {
  color: #fff;
}

.page-title .block p {
  color: #fff;
}

.page-title .breadcumb-nav {
  margin-top: 60px;
  padding-top: 30px;
  border-top: 1px solid rgba(255, 255, 255, 0.06);
}

.slick-slide:focus, .slick-slide a {
  outline: none;
}

@media (max-width: 480px) {
  h2, .h2 {
    font-size: 1.3rem;
    line-height: 36px;
  }
}

.title-color {
  color: #223a66;
}

.secondary-bg {
  background: #223a66;
}

.section-title {
  margin-bottom: 70px;
}

.section-title h2 {
  color: #223a66;
}

.text-lg {
  font-size: 50px;
}

.gray-bg {
  background: #f4f9fc;
}

@media (max-width: 480px) {
  .text-lg {
    font-size: 28px;
  }
}

@media (max-width: 400px) {
  .text-lg {
    font-size: 28px;
  }
}

#navbarmain {
  padding: 20px 0px;
}

#navbarmain .nav-link {
  font-weight: 600;
  padding: 10px 15px;
  color: #222;
  font-family: "Exo", sans-serif;
  text-transform: capitalize;
  font-size: 16px;
  -webkit-transition: all .25s ease;
  -moz-transition: all .25s ease;
  -ms-transition: all .25s ease;
  -o-transition: all .25s ease;
  transition: all .25s ease;
}

.dropdown-toggle::after {
  display: none;
}

.navbar-brand {
  margin-top: 10px;
}

.dropdown .dropdown-menu {
  position: absolute;
  display: block;
  background: #fff;
  min-width: 240px;
  top: 130%;
  left: 0;
  right: 0px;
  opacity: 0;
  padding: 0px;
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  border: 0px;
  border-top: 5px solid #e12454;
  border-radius: 0px;
}

.dropdown:hover .dropdown-menu {
  opacity: 1;
  visibility: visible;
  top: 115%;
}

.dropdown .dropdown-item {
  padding: 13px 20px;
  border-bottom: 1px solid #eee;
  background: transparent;
  font-weight: 400;
  color: #555;
}

.dropdown .dropdown-item:hover {
  color: #e12454;
}

.header-top-bar {
  background: #223a66;
  font-size: 14px;
  padding: 10px 0px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
  color: #fff;
}

.top-bar-info li a {
  color: #fff;
  margin-right: 20px;
}

.top-right-bar a span {
  color: #fff;
  font-weight: 600;
  letter-spacing: 1px;
}

.top-right-bar a i {
  color: #fff;
  margin-right: 10px;
}

.bg-1 {
  background: url("../images/bg/22.jpg") no-repeat 50% 50%;
  background-size: cover;
  position: relative;
}

.banner {
  position: relative;
  overflow: hidden;
  background: #fff;
  background: url("../images/bg/slider-bg-1.jpg") no-repeat;
  background-size: cover;
  min-height: 550px;
}

.banner .block {
  padding: 80px 0px 160px;
}

.banner .block h1 {
  font-size: 60px;
  line-height: 1.2;
  letter-spacing: -1.2px;
  text-transform: capitalize;
  color: #223a66;
}

.letter-spacing {
  letter-spacing: 2px;
}

.text-color {
  color: #223a66;
}

.text-color-2 {
  color: #e12454;
}

.divider {
  width: 40px;
  height: 5px;
  background: #e12454;
}

@media (max-width: 480px) {
  .banner .block h1 {
    font-size: 38px;
    line-height: 50px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 400px) {
  .banner .block h1 {
    font-size: 28px;
    line-height: 40px;
  }
  .banner {
    min-height: 450px;
    background: #fff !important;
  }
}

@media (max-width: 768px) {
  .banner .block h1 {
    font-size: 56px;
    line-height: 70px;
  }
  .banner {
    background: #fff !important;
  }
}

@media (max-width: 992px) {
  .banner {
    background: #fff !important;
  }
}

.o-firmie-img img {
  border-radius: 5px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.award-img {
  height: 120px;
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  background: #eff0f3;
}

.appoinment-content {
  position: relative;
}

.appoinment-content img {
  width: 85%;
}

.appoinment-content .emergency {
  position: absolute;
  content: "";
  right: 10px;
  bottom: 20px;
  background: #223a66;
  padding: 48px;
}

.appoinment-content .emergency h2 {
  color: #fff;
}

.appoinment-content .emergency i {
  margin-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}

.appoinment-form {
  margin-top: 40px;
}

.appoinment-form .form-control {
  background: #f4f9fc;
  height: 55px;
  border-color: rgba(0, 0, 0, 0.05);
}

.appoinment-form textarea.form-control {
  height: auto;
}

.client-thumb {
  text-align: center;
}

.features {
  margin-top: -70px;
}

.feature-item {
  flex-basis: 33.33%;
  margin: 0px 10px;
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 15px 15px 15px 15px;
  box-shadow: 0px 0px 30px 0px rgba(0, 42, 106, 0.1);
}

.feature-item .feature-icon i {
  font-size: 50px;
  color: #223a66;
}

.feature-item h4 {
  color: #223a66;
}

.feature-item p {
  font-size: 14px;
}

.feature-section.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.05) !important;
}

.w-hours li {
  padding: 6px 0px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
}

.counter-stat {
  text-align: center;
  padding: 55px 0px 40px 0px;
  position: relative;
}

.counter-stat i {
  display: block;
  color: rgba(255, 255, 255, 0.06);
  font-size: 70px;
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  -webkit-transform: translateY(25px);
  -moz-transform: translateY(25px);
  -ms-transform: translateY(25px);
  -o-transform: translateY(25px);
  transform: translateY(25px);
}

.counter-stat span {
  font-size: 70px;
  color: #fff;
}

.counter-stat p {
  margin-bottom: 0px;
  color: rgba(255, 255, 255, 0.7);
}

.mb--80 {
  margin-bottom: -80px;
}

.service {
  padding-top: 180px;
}

.service .service-item {
  background: #fff;
  padding: 30px;
  border-radius: 5px;
}

.service .icon {
  float: left;
  margin-bottom: 10px;
}

.service i {
  color: #e12454;
}

.service h4 {
  padding-left: 20px;
}

.service .content {
  clear: both;
}

.service-block {
  padding: 20px;
  margin-top: 40px;
  border: 1px solid rgba(0, 0, 0, 0.03);
  box-shadow: 0 0 38px rgba(21, 40, 82, 0.07);
}

.service-block img {
  width: 100%;
  margin-top: -60px;
  border: 5px solid #fff;
}

.department-service {
  margin-bottom: 40px;
}

.department-service li {
  margin-bottom: 10px;
}

.department-service li i {
  color: #e12454;
}

.doctors .btn-group .btn {
  border-radius: 0px;
  margin: 0px 2px;
  text-transform: capitalize;
  font-size: 16px;
  padding: .6rem 1.5rem;
  cursor: pointer;
}

.doctors .btn-group .btn.active {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group .btn.focus {
  box-shadow: none !important;
  border-color: transparent;
}

.doctors .btn-group .btn:focus {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group .btn:hover {
  box-shadow: none !important;
  border-color: transparent;
  background: #e12454;
  color: #fff;
}

.doctors .btn-group > .btn-group:not(:last-child) > .btn, .doctors .btn-group > .btn:not(:last-child):not(.dropdown-toggle), .doctors .btn-group > .btn:not(:first-child) {
  border-radius: 3px;
}

.doctor-inner-box {
  overflow: hidden;
}

.doctor-inner-box .doctor-profile {
  overflow: hidden;
  position: relative;
  box-shadow: 0px 8px 16px 0px rgba(200, 183, 255, 0.2);
}

.doctor-inner-box .doctor-profile .doctor-img {
  -webkit-transition: all .35s ease;
  -moz-transition: all .35s ease;
  -ms-transition: all .35s ease;
  -o-transition: all .35s ease;
  transition: all .35s ease;
}

.doctor-inner-box .doctor-profile .doctor-img:hover {
  -webkit-transform: scale(1.1);
  -moz-transform: scale(1.1);
  -ms-transform: scale(1.1);
  -o-transform: scale(1.1);
  transform: scale(1.1);
}

.lh-35 {
  line-height: 35px;
}

.doctor-info li {
  margin-bottom: 10px;
  color: #222;
}

.doctor-info li i {
  margin-right: 20px;
  color: #e12454;
}

.read-more {
  color: #223a66;
}

@media (max-width: 480px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 400px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

@media (max-width: 768px) {
  .doctors .btn-group {
    display: block;
  }
  .doctors .btn-group .btn {
    margin: 8px 3px;
  }
}

.cta {
  background: url("../images/bg/bg-4.jpg") no-repeat 50% 50%;
  background-size: cover;
  position: relative;
}

.cta:before {
  position: absolute;
  content: "";
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background: rgba(34, 58, 102, 0.95);
}

.mb-30 {
  margin-bottom: 30px;
}

.text-color-primary {
  color: #223a66;
}

.cta-section {
  margin-bottom: -80px;
}

.cta-2 {
  background: url("../images/bg/cta-bg.png") no-repeat;
  background-position: center center;
}

.cta-page {
  background: url("../images/bg/banner.jpg") no-repeat;
  background-size: cover;
  position: relative;
}

.testimonial {
  position: relative;
}

.testimonial:before {
  width: 48%;
  height: 100%;
  top: 0;
  left: 0px;
  position: absolute;
  content: "";
  background: url("../images/bg/bg-2.jpg") no-repeat 50% 50%;
}

.testimonial .slick-dots {
  text-align: left;
}

.testimonial-block {
  position: relative;
  margin-bottom: 20px;
}

.testimonial-block p {
  background: #fff;
  font-size: 18px;
}

.testimonial-block .client-info {
  margin-bottom: 20px;
}

.testimonial-block .client-info h4 {
  margin-bottom: 0px;
}

.testimonial-block i {
  font-size: 60px;
  position: absolute;
  right: 46px;
  bottom: 89px;
  opacity: .08;
}

.testimonial-block .slick-dots {
  text-align: left;
}

.testimonial-wrap-2 .slick-dots {
  margin-left: -10px;
}

.testimonial-block.style-2 {
  background: #fff;
  padding: 30px;
  margin: 0px 4px;
  margin-bottom: 30px;
}

.testimonial-block.style-2 .testimonial-thumb {
  float: left;
}

.testimonial-block.style-2 .testimonial-thumb img {
  width: 80px;
  height: 80px;
  border-radius: 100%;
  margin-right: 20px;
  margin-bottom: 30px;
  border: 5px solid #eff0f3;
  margin-top: -5px;
}

.testimonial-block.style-2 .client-info p {
  clear: both;
  background: transparent;
}

.testimonial-block.style-2 i {
  bottom: -20px;
  color: #e12454;
  opacity: .3;
}

@media (max-width: 480px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 400px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 768px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

@media (max-width: 992px) {
  .testimonial-wrap {
    margin-left: 0px;
  }
  .testimonial::before {
    display: none;
  }
}

.contact-form-wrap .form-group {
  margin-bottom: 20px;
}

.contact-form-wrap .form-group .form-control {
  height: 60px;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  width: 100%;
  background: #f4f9fc;
}

.contact-form-wrap .form-group-2 {
  margin-bottom: 13px;
}

.contact-form-wrap .form-group-2 textarea {
  height: auto;
  border: 1px solid #EEF2F6;
  box-shadow: none;
  background: #f4f9fc;
  width: 100%;
}

.social-icons li {
  margin: 0 6px;
}

.social-icons a {
  margin-right: 10px;
  font-size: 18px;
}

.google-map {
  position: relative;
}

.google-map #map {
  width: 100%;
  height: 500px;
}

.mt-90 {
  margin-top: 90px;
}

.contact-block {
  text-align: center;
  border: 5px solid #EEF2F6;
  padding: 50px 25px;
}

.contact-block i {
  font-size: 50px;
  margin-bottom: 15px;
  display: inline-block;
  color: #e12454;
}

.blog-item-content h2 {
  font-weight: 600;
  font-size: 38px;
}

/*=================================================================
  Single Blog Page
==================================================================*/
.nav-links .page-numbers {
  display: inline-block;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  background: #eee;
  text-align: center;
  padding-top: 13px;
  font-weight: 600;
  margin-right: 10px;
}

.nav-links .page-numbers:hover {
  background: #223a66;
  color: #fff;
}

.nav-links .page-numbers.current {
  background: #223a66;
  color: #fff;
}

.comment-area .comment-thumb {
  margin-right: 20px;
  margin-bottom: 30px;
}

.comment-area h5 {
  font-size: 18px;
  font-weight: 500;
}

.comment-area span {
  font-size: 14px;
}

.posts-nav h6 {
  font-weight: 500;
}

.quote {
  font-size: 22px;
  color: #223a66;
  padding: 40px;
  font-style: italic;
  border-left: 5px solid #e12454;
  margin: 25px 0px;
}

.tag-option a {
  border: 1px solid #eff0f3;
  padding: 6px 12px;
  color: #6F8BA4;
  font-size: 14px;
}

.comment-form .form-control {
  background: #f7f8fb;
  border-radius: 5px;
  border-color: #f7f8fb;
  height: 50px;
}

.comment-form textarea.form-control {
  height: auto;
}

.post.post-single {
  border: none;
}

.post.post-single .post-thumb {
  margin-top: 30px;
}

.post-sub-heading {
  border-bottom: 1px solid #dedede;
  padding-bottom: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 20px;
}

.post-social-share {
  margin-bottom: 50px;
}

.post-comments {
  margin: 30px 0;
}

.post-comments .media {
  margin-top: 20px;
}

.post-comments .media > .pull-left {
  padding-right: 20px;
}

.post-comments .comment-author {
  margin-top: 0;
  margin-bottom: 0px;
  font-weight: 500;
}

.post-comments .comment-author a {
  color: #223a66;
  font-size: 14px;
  text-transform: uppercase;
}

.post-comments time {
  margin: 0 0 5px;
  display: inline-block;
  color: #808080;
  font-size: 12px;
}

.post-comments .comment-button {
  color: #223a66;
  display: inline-block;
  margin-left: 5px;
  font-size: 12px;
}

.post-comments .comment-button i {
  margin-right: 5px;
  display: inline-block;
}

.post-comments .comment-button:hover {
  color: #223a66;
}

.post-excerpt {
  margin-bottom: 60px;
}

.post-excerpt h3 a {
  color: #000;
}

.post-excerpt p {
  margin: 0 0 30px;
}

.post-excerpt blockquote.quote-post {
  margin: 20px 0;
}

.post-excerpt blockquote.quote-post p {
  line-height: 30px;
  font-size: 20px;
  color: #223a66;
}

.comments-section {
  margin-top: 35px;
}

.author-o-firmie {
  margin-top: 40px;
}

.post-author {
  margin-right: 20px;
}

.post-author > img {
  border: 1px solid #dedede;
  max-width: 120px;
  padding: 5px;
  width: 100%;
}

.comment-list ul {
  margin-top: 20px;
}

.comment-list ul li {
  margin-bottom: 20px;
}

.comment-wrap {
  border: 1px solid #dedede;
  border-radius: 1px;
  margin-left: 20px;
  padding: 10px;
  position: relative;
}

.comment-wrap .author-avatar {
  margin-right: 10px;
}

.comment-wrap .media .media-heading {
  font-size: 14px;
  margin-bottom: 8px;
}

.comment-wrap .media .media-heading a {
  color: #223a66;
  font-size: 13px;
}

.comment-wrap .media .comment-meta {
  font-size: 12px;
  color: #888;
}

.comment-wrap .media p {
  margin-top: 15px;
}

.comment-reply-form {
  margin-top: 80px;
}

.comment-reply-form input, .comment-reply-form textarea {
  height: 35px;
  border-radius: 0;
  box-shadow: none;
}

.comment-reply-form input:focus, .comment-reply-form textarea:focus {
  box-shadow: none;
  border: 1px solid #223a66;
}

.comment-reply-form textarea, .comment-reply-form .btn-main {
  height: auto;
}

.sidebar-widget {
  margin-bottom: 30px;
  padding-bottom: 35px;
}

.sidebar-widget h5 {
  margin-bottom: 30px;
  position: relative;
  padding-bottom: 15px;
}

.sidebar-widget h5:before {
  position: absolute;
  content: "";
  left: 0px;
  bottom: 0px;
  width: 35px;
  height: 3px;
  background: #e12454;
}

.sidebar-widget.latest-post .media img {
  border-radius: 7px;
}

.sidebar-widget.latest-post .media h6 {
  font-weight: 500;
  line-height: 1.4;
}

.sidebar-widget.latest-post .media p {
  font-size: 12px;
}

.sidebar-widget.category ul li {
  margin-bottom: 10px;
}

.sidebar-widget.category ul li a {
  color: #222;
  -webkit-transition: all 0.3s ease;
  -moz-transition: all 0.3s ease;
  -ms-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.sidebar-widget.category ul li a:hover {
  color: #223a66;
  padding-left: 5px;
}

.sidebar-widget.category ul li span {
  margin-left: 10px;
}

.sidebar-widget.tags a {
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: .075em;
  line-height: 41px;
  height: 41px;
  font-weight: 500;
  border-radius: 20px;
  color: #666;
  display: inline-block;
  background-color: #eff0f3;
  margin: 0 7px 10px 0;
  padding: 0 25px;
  -webkit-transition: all .2s ease;
  -moz-transition: all .2s ease;
  transition: all .2s ease;
}

.sidebar-widget.tags a:hover {
  color: #fff;
  background: #223a66;
}

.sidebar-widget.schedule-widget {
  background: #f4f9fc;
  padding: 25px;
}

.sidebar-widget.schedule-widget ul li {
  padding: 10px 0px;
  border-bottom: 1px solid #eee;
}

.search-form {
  position: relative;
}

.search-form i {
  position: absolute;
  right: 15px;
  top: 35%;
}

.footer {
  padding-bottom: 10px;
}

.footer .copyright a {
  font-weight: 600;
}

.lh-35 {
  line-height: 35px;
}

.logo {
  font-weight: 600;
  letter-spacing: 1px;
}

.logo h3 {
  color: #223a66;
}

.logo span {
  color: #223a66;
}

.widget .divider {
  height: 3px;
}

.widget h4 {
  color: #223a66;
}

.widget .footer-menu a {
  color: #6F8BA4;
}

.widget .footer-menu a:hover {
  color: #e12454;
}

.footer-contact-block span {
  font-weight: 400;
  color: #6F8BA4;
}

.footer-contact-block i {
  font-size: 20px;
}

.footer-btm {
  border-top: 1px solid rgba(0, 0, 0, 0.06);
}

.footer-socials li a {
  width: 45px;
  height: 45px;
  background: #6F8BA4;
  color: #fff;
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding-top: 12px;
}

.widget-contact h6 {
  font-weight: 500;
  margin-bottom: 18px;
}

.widget-contact h6 i {
  color: #e12454;
}

.subscribe {
  position: relative;
}

.subscribe .form-control {
  border-radius: 50px;
  height: 60px;
  padding-left: 25px;
  border-color: #eee;
}

.subscribe .btn {
  position: absolute;
  right: 6px;
  top: 6px;
}

.backtop {
  position: fixed;
  background: #e12454;
  z-index: 9999;
  display: inline-block;
  right: 55px;
  width: 60px;
  height: 60px;
  bottom: 50px;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  border-radius: 50px;
}

.backtop i {
  color: #fff;
  font-size: 20px;
}

.reveal {
  transition: all .3s;
  cursor: pointer;
  opacity: 1;
}
